@use "sass:map";

// Colors
$primary-active-main: var(--primary-active-main);
$primary-active-main-rgb: var(--primary-active-main-rgb);
$primary-active-dark: var(--primary-active-dark);
$primary-active-dark-rgb: var(--primary-active-dark-rgb);
$primary-active-light: var(--primary-active-light);
$primary-active-light-rgb: var(--primary-active-light-rgb);
$primary-gradient-active-main: var(--primary-gradient-active-main);
$primary-gradient-active-skew: var(--primary-gradient-active-skew);
$primary-gradient-active-light: var(--primary-gradient-active-light);
$primary-passive-main: var(--primary-passive-main);
$primary-passive-main-rgb: var(--primary-passive-main-rgb);
$primary-gradient-passive-main: var(--primary-gradient-passive-main);
$secondary-gradient-active-main: var(--secondary-gradient-active-main);
$tertiary-gradient-active-main: var(--tertiary-gradient-active-main);
$tertiary-gradient-active-main-fill: var(--tertiary-gradient-active-main-fill);
$secondary-flat-gray-dark: #9a968e;
$secondary-gray-main: #dddbd0;
$secondary-gray-dark: #b2aea4;
$secondary-gray-lighter: #f6f6f4;
$secondary-gray-light: #eeedea;
$secondary-gray-ultra-light: #fcfcfb;
$box-shadow-default: 0 4px 12px rgb(0 0 0 / .2);
$caramel: #d56905;
$caramel-light: #f5e0cc;
$caramel-ultra-light: #fbeadb;
$caramel-active: var(--button-accent-background-hover);
$gold: #c9ad01;
$black: #000;
$white: #fff;
$red: #e84a4a;
$red-alert: #f00;
$overall: #9a968e;
$stable: #84cc06;
$recovery: #00991e;
$unstable: #f1832c;
$crisis: #e84a4a;
$very-negative: #6f6b64;
$negative: #a19d94;
$neutral: #b8c7a6;
$positive: #9cda4e;
$very-positive: #15ce33;
$caramel: #d56905;
$caramel-light: #f5e0cc;
$caramel-ultra-light: #fbeadb;
$caramel-active: var(--button-accent-background-hover);
$tertiary-colors: (
    "green01": #00991e,
    "green02": #63b312,
    "green03": #b3c809,
    "yellow": #ffdc00,
    "orange01": #f8b116,
    "orange02": #f1832c,
    "red": $red,
    "black": $black,
    "white": $white,
    "gray": rgb(0 0 0 / .5),
);
$contextual-colors: (
    "info": (
        "50": #eff6ff,
        "100": #dbeafe,
        "200": #bfdbfe,
        "300": #93c5fd,
        "400": #60a5fa,
        "500": #3b82f6,
        "600": #2563eb,
        "700": #1d4ed8,
        "800": #1e40af,
        "900": #1e3a8a,
        "text": #1e40af,
        "bg": #bfdbfe
    ),
    "success": (
        "50": #ecfdf5,
        "100": #d1fae5,
        "200": #a7f3d0,
        "300": #6ee7b7,
        "400": #34d399,
        "500": #10b981,
        "600": #059669,
        "700": #047857,
        "800": #065f46,
        "900": #064e3b,
        "text": #065f46,
        "bg": #a7f3d0
    ),
    "warning": (
        "50": #fff7ed,
        "100": #ffedd5,
        "200": #fed7aa,
        "300": map.get($tertiary-colors, "orange01"),
        "400": map.get($tertiary-colors, "orange02"),
        "500": #f97316,
        "600": #ea580c,
        "700": #c2410c,
        "800": #9a3412,
        "900": #7c2d12,
        "text": #9a3412,
        "bg": #fed7aa
    ),
    "error": (
        "50": #fef2f2,
        "100": #fee2e2,
        "200": #fecaca,
        "300": #fca5a5,
        "400": #f87171,
        "500": map.get($tertiary-colors, "red"),
        "600": #dc2626,
        "700": #b91c1c,
        "800": #991b1b,
        "900": #7f1d1d,
        "text": #991b1b,
        "bg": #fecaca
    ),
);

// Fonts
$font-family: "Lato", sans-serif;
$font-size-base: 1rem !default;
$font-size-xl: 1.125rem; // 18px
$font-size-lg: .875rem; // 14px
$font-size-md: .75rem; // 12px
$font-size-sm: .625rem; // 10px
$font-size-h1: 2.25rem;
$font-size-h2: 1.5rem;

// Dropdown
$dropdown-padding-y: 15px;
$dropdown-padding-x: 24px;
$dropdown-radius: 20px;

// Header
$header-height-portfolio-share: 136px;
$header-height-simulation: 50px;
$header-height-short: 11.5rem;
$header-background: var(--header-background);

// Toolbar
$toolbar-height: 50px;

// Footer
$footer-height-edit-scenario: 92px;

// Market-Event-Menu
$market-event-menu-height: 48px;
$market-event-menu-item-width: 240px;
$market-event-menu-item-segment-width: 40px;

// Sidebar
$sidebar-background: var(--sidebar-background);
$sidebar-item: var(--sidebar-item);
$sidebar-item-active: var(--sidebar-item-active);
$width-sidebar: 200px;
$width-sidebar-reduced: 80px;

// Vidéos
$videos-background: var(--videos-background);

// Workspaces
$workspaces-header-background: var(--workspaces-header-background);
$workspaces-background: var(--workspaces-background);

// Advanced filter
$advanced-filter-background: var(--advanced-filter-background);
$advanced-filter-box-shadow: var(--advanced-filter-box-shadow);

// Advanced sort
$advanced-sort-background: var(--advanced-sort-background);
$advanced-sort-card-background: var(--advanced-sort-card-background);

// Sort menu
$sort-menu-background: var(--sort-menu-background);
$sort-menu-dark-background: var(--sort-menu-dark-background);
$sort-menu-text-color: var(--sort-menu-text-color);

// Shares trendings
$shares-trendings-background: var(--shares-trendings-background);
$shares-trendings-heading-color: var(--shares-trendings-heading-color);

// Simulations
$line-list-position-text-color: var(--line-list-position-text-color);

// Modals
$modal-background: var(--modal-background);

// Buttons
$buttons-palette: (
    "solid": (
        "accent": (
            "background": var(--button-accent-background),
            "color": var(--button-accent-color),
            "hoverBackgroundColor": var(--button-accent-background-hover),
            "hoverColor": var(--button-accent-color),
        ),
        "primary": (
            "background": var(--button-primary-background),
            "color": var(--button-primary-color),
        ),
        "secondary": (
            "background": var(--button-secondary-background),
            "color": var(--button-secondary-color),
        ),
        "secondary-gray": (
            "background": var(--button-secondary-gray-background),
            "color": var(--button-secondary-gray-color),
        ),
        "black": (
            "background": var(--button-black-background),
            "color": var(--button-black-color),
        ),
        "white": (
            "background": var(--button-white-background),
            "color": var(--button-white-color),
        )
    ),
    "outline": (
        "primary": (
            "background": var(--button-outline-primary-background),
            "color": var(--button-outline-primary-color),
            "hoverColor": $white,
        ),
        "secondary": (
            "background": var(--button-outline-secondary-background),
            "color": var(--button-outline-secondary-color),
            "hoverColor": $white,
        ),
        "secondary-gray": (
            "background": var(--button-outline-secondary-gray-background),
            "color": var(--button-outline-secondary-gray-color),
            "hoverColor": var(--button-outline-secondary-gray-color),
        ),
        "black": (
            "background": var(--button-outline-black-background),
            "color": var(--button-outline-black-color),
            "hoverColor": $white,
        ),
        "white": (
            "background": var(--button-outline-white-background),
            "color": var(--button-outline-white-color),
            "hoverColor": $white,
        ),
        "accent": (
            "background": var(--button-accent-background),
            "color": var(--button-accent-color),
            "hoverBackgroundColor": var(--button-accent-background-hover),
            "hoverColor": var(--button-accent-color),
        ),
    )
);

// Logos
$logo-icon: var(--logo-icon);
$logo-text: var(--logo-text);

// Guard page
$background-pdf: var(--background-guard-page);

// Common icon paths
$lock-closed-icon: var(--lock-closed-icon);

:root {
    @media print {
        --primary-gradient-active-light: #fbfbfb;
        --header-background: #f2f2f2;

        /* Firefox */
        @document url-prefix("") {
            --header-background: linear-gradient(180deg, #fff 0%, #eee 100%), #fff;
        }
    }
}
