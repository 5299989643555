@import "./variables";

.cdk-overlay-pane {
    transition: all .1s ease-in-out;
}

.dropdown {
    display: flex;
    min-width: fit-content;
    max-width: 400px;
    flex-direction: column;

    &--lg {
        min-width: 360px;
    }

    &--bg-black {
        border-radius: 16px;
        background: linear-gradient(180deg, rgb(27 27 43 / .75) 0%, $primary-passive-main 100%), $white;
        color: $white;
    }

    &__section {
        &--bg-black {
            background: linear-gradient(180deg, rgb(27 27 43 / .75) 0%, $primary-passive-main 100%), $white;
            color: $white;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        padding: .75rem 0;
        border: 0;
        border-bottom: 1px solid $secondary-gray-main;
        margin: 0 1.5rem;
        background: none;
        cursor: pointer;

        &:hover {
            color: $primary-active-main;
        }

        &--disabled {
            pointer-events: none;

            eb-label {
                opacity: .3;
            }
        }

        &--disabled-alt {
            cursor: not-allowed;

            &:hover {
                color: $black;
            }

            eb-label {
                opacity: .3;
            }
        }

        &--without-border { border: none; }

        &--bg-active-main {
            padding: .75rem 1.5rem;
            margin: 0;
            background: $primary-active-main;
            color: $white;

            &:hover {
                color: $black;
            }
        }

        &--bg-black {
            padding: .75rem 1.5rem;
            margin: 0;
            background: linear-gradient(180deg, rgb(27 27 43 / .75) 0%, $primary-passive-main 100%), $white;
            color: $white;
        }

        &--border-black {
            border-bottom: 1px solid $primary-passive-main;
        }

        &--child {
            padding: .75rem 1rem;
        }

        &--child-disabled {
            &:hover {
                color: $black;
                cursor: not-allowed;
            }

            eb-label {
                opacity: .3;
            }
        }

        &:last-child {
            border: none;
            border-radius: 0 0 $dropdown-radius $dropdown-radius;
        }

        &:first-child {
            border-radius: $dropdown-radius $dropdown-radius 0 0;
        }
    }
}
