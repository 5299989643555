@use "@angular/material" as mat;

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "src/app/styles/typography";

/* You can add global styles to this file, and also import other style files */
@import "src/app/styles/variables";

// Commons

@import "src/app/styles/animations";
@import "src/app/styles/card-list";
@import "src/app/styles/dropdown";
@import "src/app/styles/inputs";
@import "src/app/styles/line-list";
@import "src/app/styles/little-card";
@import "src/app/styles/snackbar";
@import "src/app/styles/market-cycle";
@import "src/app/styles/nav-tab";
@import "src/app/styles/authorisation";
@import "src/app/styles/range";
@import "src/app/styles/grid-system";

// Utilities
@import "src/app/styles/utilities";

/* CDK Overlay styles */
@import "@angular/cdk/overlay-prebuilt.css";
@import "normalize.css/normalize.css";

html,
body {
    font-family: $font-family;
    font-size: 16px;
}

$dark-primary-text: rgba(#000, .87);
$envestboard-palette: (
    50: $primary-active-light,
    100: $primary-active-light,
    200: $primary-active-light,
    300: $primary-active-light,
    400: $primary-active-main,
    500: $primary-active-main,
    600: $primary-gradient-active-main,
    700: $primary-active-dark,
    800: $primary-active-dark,
    900: $primary-active-dark,
    a100: $primary-active-light,
    a200: $primary-active-main,
    a400: $primary-active-dark,
    a700: $primary-gradient-active-main,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $dark-primary-text,
        600: $dark-primary-text,
        700: $dark-primary-text,
        800: $dark-primary-text,
        900: $dark-primary-text,
        a100: $dark-primary-text,
        a200: $dark-primary-text,
        a400: $dark-primary-text,
        a700: $dark-primary-text,
    )
);
$my-primary: mat.m2-define-palette($envestboard-palette, 300);
$my-accent: mat.m2-define-palette($envestboard-palette, a200, a100, a400);
$my-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $my-primary,
            accent: $my-accent,
        ),
    )
);

@include mat.datepicker-theme($my-theme);

.to-print {
    display: none !important;
}

@media print {
    div[id*="appzi"] {
        display: none;
    }

    @page {
        width: 100%;
        padding: 0;
        margin: 0;
        size: a4 portrait;
    }

    .cdk-overlay-container {
        display: none;
    }

    .no-print {
        display: none !important;
    }

    .to-print {
        display: block !important;
    }

    .avoid-break-inside {
        page-break-inside: avoid;
    }
}

.cdk-overlay-backdrop-disabled {
    pointer-events: none;
}
